import { IData } from "./data-types";
// @ts-ignore
import aboutHero from "../images/about_hero.svg";
// @ts-ignore
import professionalCommitted from "../images/professional_committed.svg";
// @ts-ignore
import dataInformed from "../images/data_informed.svg";
// @ts-ignore
import honestTransparent from "../images/honest_transparent.svg";
// @ts-ignore
import richard from "../images/richard.svg";
// @ts-ignore
import woody from "../images/woody.svg";
import { AboutTitle } from "../components/layoutElements";

const aboutData: IData = {
  title: "About Peers Care",
  hero: {
    image_src: aboutHero,
    text_title: AboutTitle,
    text_body:
      "We believe medicine is about the wellness of the patient and the provider.",
  },
  mission: {
    text_title: "Our Mission",
    text_body:
      "We are creating tools to assess and reduce healthcare provider burnout by promoting their well being and empowering them to love their patients, their job, and themselves. Our mission is to create tailored solutions that are personalized to the healthcare provider’s individual needs. Because the lives of providers are already so overburdened, our solutions must fit seamlessly into their lives, even replacing some of their existing workload.",
  },
  principles: {
    text_title: "Guiding Principes",
    text_body:
      "We believe that that providers’ are empowered to have a voice with respect to their personal needs that includes their overall wellness, flexibility in work life balance, and enables them to work towards their career goals. We are guided by our princicples to ensure we are not adding more burden.",
    cards: [
      {
        image_src: professionalCommitted,
        text_title: "Professional and Committed ",
        text_body:
          "Our product will always be professional and confident when interacting with users, helping them to feel as if they are in good hands with professional language and solutions",
      },
      {
        image_src: dataInformed,
        text_title: "Data-Informed and Deliberate",
        text_body:
          "Our product will be data informed and thus always be committed to an individual's unique needs, while pushing the boundaries of what is possible (providing a sense of deliberate delight).",
      },
      {
        image_src: honestTransparent,
        text_title: "Honest and Transparent",
        text_body:
          "Our product will always be transparent and respectful with what is collected, while still enabling the user’s voice to help create solutions.",
      },
    ],
  },
  team: {
    text_title: "Our Team",
    text_body: "",
    members: [
      {
        name: "Richard Loomis, MD ",
        image_src: richard,
      },
      {
        name: "Woody MacDuffie",
        image_src: woody,
      },
    ],
  },
};

export { aboutData };
