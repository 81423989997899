/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { useTheme } from "../components/ThemeProvider";
import * as React from "react";
import { Helmet } from "react-helmet";
import {
  Hero,
  MissionCTA as Mission,
  CardLayout as GuidingPrinciples,
  OurTeam,
} from "../components/layoutElements";
import { aboutData as data } from "../data/about-data";
import { IPageSection } from "../data/data-types";
import { ITheme } from "../style/theme";

function About() {
  const theme = useTheme() as ITheme;
  const heroData = data.hero as IPageSection;
  const guidingPData = data.principles as IPageSection;
  const missionData = data.mission as IPageSection;

  return (
    <>
      <Helmet>
        <title>{data.title}</title>
      </Helmet>
      <main
        css={css({
          backgroundColor: theme.colors.alabaster,
        })}
      >
        <Hero data={heroData} imageWidth="50%" />
        <Mission data={missionData} />
        <GuidingPrinciples data={guidingPData} />
        <OurTeam data={data} />
      </main>
    </>
  );
}

export default About;
